#fullpage .contact{
    text-align: left;
}
.contactcontainer{
    z-index: 9999999;
    .mainform{
        display: none;
        background-color: #fff;
    }
}

.contact-box{
  padding: 10px;
  margin: 30px;
  font-family: $font-family-base;
  font-size: 15px;
  border: .5px solid #dba63c;
  border-radius: 5px;
  background-color: #dba63c;

  a{
    text-decoration: underline;
    color: #fff;
  }
}

div#section1.workback{
//    background-image: url("/assets/images/Untitled-1x.png");
   //annika
}


.container.fullheight {
  height: 100%;
  .main-header {
    color: #fff;
    font-family: $font-family-sourcesans;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 60px;
    margin-top: 100px;
    text-transform: uppercase;
  }
  .main-content-rotator {
    display: table-cell;
  }
}
.capabilities {
  ul {
    list-style: none;
    float: left;
    text-align: left; //        padding: 0 0 0 10px;
    margin-left: -10px;
  }
  .col-md-4 {
    color: #fff;
    &after {
      content: "xx";
    }
  }
  .line.large {
    background-color: transparent;
    width: 85%;
    height: 3px;
    margin: 10px auto;
    opacity: 0.7;
    display: block;
  }
  .grouping-box {
        border: 2px solid  rgba(255, 255, 255, 0.5);
        border-radius: 5px; 
        -moz-border-radius: 5px; 
        -webkit-border-radius: 5px; 
        border: 2px solid rgba(255, 255, 255, 0.5);
        margin: 30px 0px 0 0px;
        min-height: 250px;
        height: 250px;
        overflow: scroll;
        }
  .view-grouping-header {
    //        padding: 0 20px 0 20px;
    //        padding: 0 20px 0 20px;
    border-bottom: 0 solid #fff;
    //font-family: $font-family-raleway;
    //font-family: $font-family-sourcesans;
    h2 {
      color: #dba63c;
      color: #fff4fc;
        font-family: $font-family-sourcesans;
        //font-family: $font-family-raleway;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1px;
    }
  }
  .view-grouping-content {
    border-top: 0 solid #fff; //        height: 200px;
    //        min-height: 200px;
    font-family: $font-family-sourcesans;
    font-size: 19px;
    line-height: 30px;
    color: #fff;
    opacity: 0.8;
    font-weight: 300;
    ul {
      padding-top: 0;
    }
  }
  // [class*="col-"] {
  //    background-clip: padding-box;
  //    border: 10px solid #ccc;
  //    }
}
@media (max-width: 768px) {
  .capabilities {
    .grouping-box {
      min-width: 100%;
      margin-top: 20px;
      padding: 0;
    }
    .view-grouping-content {
      height: auto;
      min-height: inherit;
    }
  }
  #section0 {
    h1 {
      font-size: 60px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .intropage {
    .logo {
      width: 90%;
    }

  }
}
.v-wrap {
  height: 70%;
  text-align: center;
  white-space: nowrap;
}
.v-wrap:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  margin-right: -.25em;
  /*might want to tweak this. .25em for extra white space */
  height: 100%;
}
.v-box {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  padding: 0 1em;
  background: none;
}
.service .text p {
  font-family: $font-family-sourcesans;
  font-size: 16px;
  color: #FFF;
  font-weight: 300;
}
#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #fff;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}
div.content {
  h4 {
    //font-family: $font-family-raleway;
    font-family: $font-family-sourcesans;
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 30px;
    color: #FFF;
    font-weight: 600;
  }
  h4 {
    color: #fff;
  }
  p {
    font-size: 15px;
    font-weight: normal;
  }
}
