// @import "../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import url(https://www.google.com/fonts#UsePlace:use/Collection:Open+Sans:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600,500);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,900);
$font-family-raleway : 'Raleway', sans-serif;
$font-family-base: 'OpenSans', sans-serif;
$font-family-sourcesans: 'Source Sans Pro', sans-serif;

// Import bootstrap and fontawesome
@import "bootstrap";
@import "font-awesome";
@import "variables";

body{
    color: $vbody-color;
    color: rgb(255, 255, 255);
	font-family: $font-family-base;
	font-size: 18px;
	position: relative;

    .line {
            display: block;
            width: 32px;
            height: 1px;
            margin: 20px auto;
            background-color: #d2d1d2;
          }

	#menu{
		position: fixed;
		top: 0;
		left: 0;
		height: 40px;
		z-index: 70;
		width: 100%;
		padding: 0;
		margin: 0;

		a {
			color: #666;
		}
	}
	
	.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
			background-color: #0E5997;
			background-color: #ccc;
			color: #fff;
		}
		
	.navbar-nav > li > a{
		padding: 5px 15px 5px 15px;
	}
    
    .navbar{
        min-height: 75px;
		background-color: #fff;
		border-color: #fff;
		
		.nav-special{
			position: absolute;
			width: 400px;
			right: 100px;
			top: 25px;
			font-family: $font-family-sourcesans;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
		}
		
		
		
		.navbar-header{
			position: relative;
		}
        
        .navlogo{
            margin-top: 5px;
            margin-left: 75px;
        }
		.navtriangle{
			position: absolute;
			position: absolute;
			top: 64px;
			left:105px;
		}
    }

}

.section {
    text-align: center;

    .firstpagemenu {
	position: absolute;
	left: 50%;
	bottom: 0;
	margin-left: -29px;
		a {
			display: inline-block;
			width: 58px;
			height: 33px;
			background: url(/assets/images/arrow-down.png) top left no-repeat;
			background-size: 100%;
			text-indent: -9999em;
			-webkit-animation: mymove 1.75s ease-in-out infinite;
			-moz-animation: mymove 1.75s ease-in-out infinite;
			animation: mymove 1.75s ease-in-out infinite;
			}
	}

}





#section0{
	background: -webkit-gradient(linear, top left, bottom left, from(#4bbfc3), to(#7baabe));
	background: -webkit-linear-gradient(#4BBFC3, #7BAABE);
	background: linear-gradient(#4BBFC3,#7BAABE);

    .intropage{
      max-width: 1600px;
      margin: 0 auto;
    }

		h1 {
			text-transform: lowercase;
			font-weight: bold;
			font-size: 102px;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
			padding:0;
	    	margin:0;
			span {
				font-weight: 100;
				}
		}

	    h2 {
	    	padding:0;
	    	margin:0;
			text-transform: lowercase;
			font-weight: 100;
			font-size: 45px;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
			-webkit-opacity: 0.5;
			-moz-opacity: 0.5;
			opacity: 0.5;
			-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);

			em {
				font-family: 'Minion Pro';
				font-style: italic;
				}
		}
}

#section1{

    background: #00427c;
    background: -moz-linear-gradient(-37deg,#00427c 30%,#3079bd 100%);
    background: -webkit-gradient(linear,left top,right bottom,color-stop(30%,#00427c),color-stop(100%,#3079bd));
    background: -webkit-linear-gradient(-37deg,#00427c 30%,#3079bd 100%);
    background: -o-linear-gradient(-37deg,#00427c 30%,#3079bd 100%);
    background: -ms-linear-gradient(-37deg,#00427c 30%,#3079bd 100%);
    background: linear-gradient(127deg,#00427c 30%,#3079bd 100%);
    
    // background: #50667f;
   // background: #142736;
}
#section2{
	background: #03bbb9;
    background: -moz-linear-gradient(-37deg,#26a18f 44%,#04fffc 100%);
    background: -webkit-gradient(linear,left top,right bottom,color-stop(30%,#26a18f),color-stop(100%,#04fffc));
    background: -webkit-linear-gradient(-37deg,#26a18f 44%,#04fffc 100%);
    background: -o-linear-gradient(-37deg,#26a18f 44%,#04fffc 100%);
    background: -ms-linear-gradient(-37deg,#26a18f 44%,#04fffc 100%);
    background: linear-gradient(127deg,#26a18f 44%,#04fffc 100%);
  
    // background: #50667f;
    // background:#1E1C19;
    // background: -moz-linear-gradient(-37deg,#1E1C19 44%,#04fffc 100%);
    // background: -webkit-gradient(linear,left top,right bottom,color-stop(30%,#1E1C19),color-stop(100%,#04fffc));
    // background: -webkit-linear-gradient(-37deg, #1E1C19 44%,#04fffc 100%);
    // background: -o-linear-gradient(-37deg,#1E1C19 44%,#04fffc 100%);
    // background: -ms-linear-gradient(-37deg,#1E1C19 44%,#04fffc 100%);
    // background: linear-gradient(127deg,#1E1C19 44%,#04fffc 100%);
}
#section3{
	background: -webkit-gradient(linear, top left, bottom left, from(#92a1ca), to(#76c2bd));
	background: -webkit-linear-gradient(#92A1CA, #76C2BD);
	background: linear-gradient(#92A1CA,#76C2BD);
}

body{

	.section#section0, .section#section12 {
		background: -webkit-gradient(linear, 0 0, 0 bottom, from(rgb(63, 66, 69)), to(rgb(31, 34, 36)));
		background: -webkit-linear-gradient(rgb(63, 66, 69), rgb(31, 34, 36));
		background: -moz-linear-gradient(#3f4245, #1f2224);
		background: -ms-linear-gradient(#3f4245, #1f2224);
		background: -o-linear-gradient(#3f4245, #1f2224);
		background: linear-gradient(rgb(63, 66, 69), rgb(31, 34, 36));
		padding-top: 0 !important;
	}

}

@import "custom";
